<script setup lang="ts">
  import InlineImage from '../../Partials/Image/InlineImage.vue'
  import type { WidgetImageData } from '../../../types/widgets/composites/ImageTypes'

  const componentMap: ComponentMap = {
    inline: InlineImage,
    wide: InlineImage,
    default: InlineImage,
  }

  const imageData = defineProps<{
    data: WidgetImageData
  }>()

  const selectedComponent = computed(
    () => componentMap[imageData.data.display_hint]
  )
</script>

<template>
  <component :is="selectedComponent" :imageData="imageData?.data" />
</template>
