<script setup lang="ts">
  import GalleryDefault from '../../Partials/Gallery/GalleryDefault.vue'

  const componentMap: ComponentMap = {
    default: GalleryDefault,
    inline: GalleryDefault,
  }

  const galleryData = defineProps<{
    data: GalleryData
  }>()

  const selectedComponent = computed(
    () => componentMap[galleryData.data[componentMapProp]]
  )
</script>

<template>
  <section class="center-content">
    <component :is="selectedComponent" :galleryData="galleryData.data" />
  </section>
</template>
