<script setup lang="ts">
  import useTracking from '../composables/Tracking'
  import NotSupported from './ui/notSupported.vue'
  import TeaserList from './Widgets/Page/TeaserList.vue'
  import Infobox from './Widgets/Composite/Infobox.vue'
  import TextItem from './Widgets/Composite/TextItem.vue'
  import ParagraphList from './Widgets/Composite/ParagraphList.vue'
  import Gallery from './Widgets/Composite/Gallery.vue'
  import Recipe from './Widgets/Composite/Recipe.vue'
  import Quote from './Widgets/Composite/Quote.vue'
  import InsertedCode from './Widgets/Composite/InsertedCode.vue'
  import Multimedia from './Widgets/Composite/Multimedia.vue'
  import Banner from './Widgets/Banner.vue'
  import Image from './Widgets/Composite/Image.vue'
  import AssociatedComposite from './Widgets/Composite/AssociatedComposite.vue'
  import Video from './Widgets/Composite/Video.vue'
  import QBrickVideo from './Widgets/Composite/QBrickVideo.vue'
  import SeoText from './Widgets/Page/SeoText.vue'
  import AuthorOverview from './Widgets/Page/AuthorOverview.vue'
  import LeadParagraph from './Widgets/Composite/LeadParagraph.vue'
  import ChaptersSummary from './Widgets/Composite/ChaptersSummary.vue'

  const props = withDefaults(defineProps<{ contents: any; page?: number }>(), {
    page: 1,
  })
  const { contents, page } = toRefs(props)
  const nuxtApp = useNuxtApp()
  const { trackInteraction } = useTracking()
  onMounted(() => {
    nuxtApp.hooks.hook('interaction:event', (data: InteractionData) => {
      const { uuid, interaction } = data
      const componentData = contents.value.find(
        (content: any) => content.uuid === uuid
      )
      if (componentData) {
        trackInteraction(
          interaction,
          'main content',
          componentData?.title,
          componentData?.type
        )
      }
    })
  })

  // Object mapping each itemType to its corresponding component
  const componentMap: Component = {
    teaser_list: TeaserList,
    infobox: Infobox,
    text_item: TextItem,
    paragraph_list: ParagraphList,
    gallery: Gallery,
    recipe: Recipe,
    quote: Quote,
    so_me: InsertedCode,
    test_quiz: InsertedCode,
    inserted_code: InsertedCode,
    video: Video,
    qbrick_video: QBrickVideo,
    multimedia: Multimedia,
    banner: Banner,
    image: Image,
    associated_composites: AssociatedComposite,
    seo_text: SeoText,
    author_overview: AuthorOverview,
    lead_paragraph: LeadParagraph,
    chapters_summary: ChaptersSummary,
  }

  const getComponent = (componentName: string) => {
    const component = componentMap[componentName]
    if (!component) {
      return {
        component: NotSupported,
        props: { widgetName: componentName },
      }
    }

    return { component }
  }

  // Computed property to find the first content item that can paginate
  const filteredContent = computed(() => {
    return contents.value.find(item => {
      return item?.content?.data?.can_paginate === true
    })
  })

  // Computed property to get the type of the filtered content
  const filteredType = computed(() => filteredContent.value?.type)

  // Watcher for contents and page
  watch([contents, page], () => {
    // Force re-evaluation of computed properties
    filteredContent.value
    filteredType.value
  })
</script>

<template>
  <component
    v-if="page === 1"
    v-for="content in contents"
    :key="content.uuid"
    :is="getComponent(content.type).component"
    :type="content.type"
    :data="content"
    v-bind="getComponent(content.type).props"
    class="center-content"
    v-interaction="{
      uuid: content.uuid,
    }"
  />

  <component
    v-else
    :key="page"
    :is="getComponent(filteredType).component"
    :data="filteredContent"
    :type="filteredType"
    class="center-content"
  />
</template>
