<script setup lang="ts">
  import SeoDefault from '../../Partials/Seo/SeoDefault.vue'
  import SeoFoldable from '../../Partials/Seo/SeoFoldable.vue'

  const componentMap: ComponentMap = {
    default: SeoDefault,
    foldable: SeoFoldable,
  }

  const SeoData = defineProps<{
    data: seoData
  }>()

  const selectedComponent = computed(
    () => componentMap[SeoData?.data?.content?.data?.display_hint || 'default']
  )
</script>

<template>
  <section>
    <component :is="selectedComponent" :SeoData="SeoData?.data?.content" />
  </section>
</template>
