<script setup lang="ts">
  const props = defineProps({ hasAccess: Boolean })

  const handleCookiePreferences = (): void => {
    try {
      window.Didomi.preferences.show()
    } catch (e) {
      // Assuming e is of type any, as the catch block in TypeScript can catch any type of error.
      console.error(e as any)
    }
  }
</script>

<template>
  <div class="lg:pb-lg lg:border-bottom-small mobile-only:px-[1rem">
    <div
      class="p-xl col-span-full mx-auto max-w-md bg-[#e3e3e3] text-center"
      v-if="!hasAccess"
    >
      <h2 class="font-large-teaser mb-lg col-span-full text-center">
        Cookie Consent required
      </h2>
      <p>
        We use cookies to enhance your experience. By continuing to visit this
        site you agree to our use of cookies.
      </p>
      <button
        @click="handleCookiePreferences"
        class="mt-md rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
      >
        Manage Cookies
      </button>
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>
