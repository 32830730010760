<script setup lang="ts">
  import QuoteStatement from '../../Partials/Quote/QuoteStatement.vue'

  const componentMap: ComponentMap = {
    statement: QuoteStatement,
  }

  const quoteData = defineProps<{
    data: QuoteData
  }>()

  const selectedComponent = computed(
    () => componentMap[quoteData.data.display_hint]
  )
</script>

<template>
  <component :is="selectedComponent" :quoteData="data" />
</template>
