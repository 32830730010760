<script setup lang="ts">
  import ParagraphListDefault from '../../Partials/ParagraphList/ParagraphListDefault.vue'

  // TODO : Make components for each of these. Defaults to the same right now.
  const componentMap: ComponentMap = {
    ordered: ParagraphListDefault,
    unordered: ParagraphListDefault,
    image: ParagraphListDefault,
    custom: ParagraphListDefault,
  }

  const paragraphListData = defineProps<{
    data: ParagraphListData
  }>()

  const selectedComponent = computed(
    () => componentMap[paragraphListData.data.display_hint]
  )
</script>

<template>
  <section class="center-content">
    <component
      :is="selectedComponent"
      :paragraphListData="paragraphListData.data"
    />
  </section>
</template>
