<script setup lang="ts">
  import AssociatedCompositeDefault from '../../Partials/AssociatedComposite/AssociatedCompositeDefault.vue'
  import AssociatedCompositeRelatedContent from '../../Partials/AssociatedComposite/AssociatedCompositeRelatedContent.vue'

  const componentMap: ComponentMap = {
    default: AssociatedCompositeDefault,
    related_content: AssociatedCompositeRelatedContent,
  }

  const associatedCompositeData = defineProps<{
    data: AssociatedCompositeData
  }>()

  /**
   * TODO : Make into universal function.
   */
  const selectedComponent = computed(
    () => componentMap[associatedCompositeData.data.display_hint]
  )
</script>

<template>
  <section
    data-component-name="Read more"
    data-component-type="Associated Composites"
  >
    <component
      :is="selectedComponent"
      :associatedCompositeData="associatedCompositeData?.data"
    />
  </section>
</template>
